// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './polyfill'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router/index'
import store from './store'
import VueCookie from 'vue-cookie'
import './assets/style.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
//uncomment comment below line a/c to project
//import './assets/mytiketi-style.css'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import { dashboardName } from '@/helper/constant.js';

import { ClientTable, Event } from "vue-tables-2";
Vue.use(ClientTable);

import vSelect from 'vue-select'
import Multiselect from 'vue-multiselect';


Vue.component('v-select', vSelect);
Vue.component('multiselect', Multiselect);

// Saif
// Vue.directive("sortable", {
//   inserted: function(el, binding) {
//     var sortable = new Sortable(el, binding.value || {});
//   }
// });
import JsonExcel from 'vue-json-excel';
Vue.component('downloadExcel', JsonExcel)
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)
import CryptoJS from "crypto-js";
import _ from "lodash";
var secretKey = "HereIsTheMySecretKey_LOL";



Vue.mixin({
  data: function () {
    return {
      dashboardName: dashboardName,
      modelCloseCount: 0,
      modalObject: {
        title: "",
        message: "",
        count: 0
      },
      // CountryId: secureStore.get('COUNID')
      secureStore: {
        // set: this.setItem(key, value),
        // get: this.setItem(key)
        set: function (key, value) {
          let setEncryptedData = CryptoJS.AES.encrypt(JSON.stringify(value), secretKey).toString();
          console.log("checking key value ", key, setEncryptedData)

          window.localStorage.setItem(key, setEncryptedData);
        },
        get: function (key) {
          let getEncryptedData = window.localStorage.getItem(key);
          if (!_.isNull(getEncryptedData))
            return JSON.parse(CryptoJS.AES.decrypt(getEncryptedData, secretKey).toString(CryptoJS.enc.Utf8))
          return null;
        },
        remove: function (key) {
          window.localStorage.removeItem(key);
        }
      }
    }
  },
  methods: {
    modelHandleOkay: function () {
      this.modelCloseCount = 1;
    },
    showConfirmationModal: function (data) {
      EventBus.$emit("ShowModel", data);
    },
    scrollToTop: function () {
      window.scrollTo(0, 0);

      // this.$router.push({
      //   path: "/banners/bannerlist"
      // });

      // window.location.replace(
      //   window.location.href.split("?")[0]
      // );

    }
    // <img v-if="splash_screen.Splash2 != ''" :src="BaseURL+splash_screen.Splash1" class="img-fluid">

    // setItem: function (key, value) {
    //   let setEncryptedData = CryptoJS.AES.encrypt(JSON.stringify(value), secretKey).toString();
    //   window.localStorage.setItem(key, setEncryptedData);
    // },
    // get: function (key) {
    //   let getEncryptedData = window.localStorage.getItem(key);
    //   if (!_.isNull(getEncryptedData))
    //     return JSON.parse(CryptoJS.AES.decrypt(getEncryptedData, secretKey).toString(CryptoJS.enc.Utf8))
    //   return null;
    // }
  }
})

// Saif
Vue.use(VueCookie);
Vue.use(BootstrapVue)
/* eslint-disable no-new */
export const EventBus = new Vue();

new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App
  }
})
