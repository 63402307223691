
import MasterService from "@/services/MasterService";
import CinemaService from "@/services/CinemaService";
import AccountReportService from "@/services/AccountReportService";

export default {
    namespaced: true,
    state: {
        eventManager: [],
        roles: [],
        countryList: [],
        cityList: [],
        stateList: [],
        cinemasList: [],
    },
    getters: {
        getAllEventManager: state => state.eventManager,
        getAllEvents: state => state.roles,
        getAllCountry: state => state.countryList,
        getAllCity: state => state.cityList,
        getAllState: state => state.stateList,
        getAllCinemas: state => state.cinemasList,
    },
    mutations: {
        SET_EVENT_MANAGER_DETAILS(state, val) {
            state.eventManager = val
        },
        SET_EVENT_DETAILS(state, val) {
            state.roles = val
        },
        SET_COUNTRY_DETAILS(state, val) {
            state.countryList = val
        },
        SET_CITY_DETAILS(state, val) {
            state.cityList = val
        },
        SET_STATE_DETAILS(state, val) {
            state.stateList = val
        },
        SET_CINEMAS_DETAILS(state, val) {
            state.cinemasList = val
        },
    },
    actions: {
        setAllEventManager: async ({ dispatch, commit, state }, payload) => {
            await commit('SET_EVENT_MANAGER_DETAILS', []);
            MasterService.getEventManagerList()
                .then(async (response) => {
                    if (response.data.data) {
                        await commit('SET_EVENT_MANAGER_DETAILS', response.data.data)
                    } else {
                        console.log(response.data);
                    }
                })
                .catch(err => {
                    console.log('Error in getting Event Manager ', err);
                })
        },
        setAllEvents: async ({ dispatch, commit, state }, payload) => {
            await commit('SET_EVENT_DETAILS', []);
            MasterService.getEventList()
                .then(async (response) => {
                    if (response.data.Status) {
                        await commit('SET_EVENT_DETAILS', response.data.Userrole)
                    } else {
                        console.log(response.data);
                    }
                })
                .catch(err => {
                    console.log('Error in getting Events ', err);
                })
        },
        setAllCountry: async ({ dispatch, commit, state }, payload) => {
            await commit('SET_COUNTRY_DETAILS', []);
            MasterService.getActiveCountries()
                .then(async (response) => {
                    if (response.data.Status) {
                        await commit('SET_COUNTRY_DETAILS', response.data.CountryList)
                    } else {
                        console.log(response.data);
                    }
                })
                .catch(err => {
                    console.log('Error in getting Country ', err);
                })
        },
        setAllCity: async ({ dispatch, commit, state }, payload) => {
            await commit('SET_CITY_DETAILS', []);
            MasterService.getCities()
                .then(async (response) => {
                    if (response.data.Status) {
                        await commit('SET_CITY_DETAILS', response.data.CountryList)
                    } else {
                        console.log(response.data);
                    }
                })
                .catch(err => {
                    console.log('Error in getting City ', err);
                })
        },
        setAllState: async ({ dispatch, commit, state }, payload) => {
            await commit('SET_STATE_DETAILS', []);
            MasterService.getStates()
                .then(async (response) => {
                    if (response.data.Status) {
                        await commit('SET_STATE_DETAILS', response.data.CountryList)
                    } else {
                        console.log(response.data);
                    }
                })
                .catch(err => {
                    console.log('Error in getting State ', err);
                })
        },
        setAllCinemas: async ({ dispatch, commit, state }, payload) => {
            console.log({ payload });
            await commit('SET_CINEMAS_DETAILS', []);
            CinemaService.getCinemas(payload)
                .then(async (response) => {
                    if (response.data.Status) {
                        await commit('SET_CINEMAS_DETAILS', response.data.CinemaList)
                    } else {
                        console.log(response.data);
                    }
                    console.log("get all Cinema",response.data,payload);
                })
                .catch(err => {
                    console.log('Error in getting AllCinemas ', err);
                })
        },
    },
}