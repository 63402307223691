import Vue from "vue";
import Router from "vue-router";
import store from "../store";

const ifNotAuthenticated = (to, from, next) => {
  // if (localStorage.getItem('authenticated') == 'true') {
  if (Vue.cookie.get("TICHTID")) {
    next();
    return;
  }
  next("/go-to/login");
};

Vue.use(Router);

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      // redirect: '/dashboard',
      redirect: (to) => {
        // return Vue.cookie.get('UPEM') && Vue.cookie.get('UPEM') == 'adminLogs' ? "/adminlogs" : "/dashboard";
        return Vue.cookie.get("UPEM") && Vue.cookie.get("UPEM") == "adminLogs"
          ? "/cinemamasters/"
          : "/dashboard";
      },
      name: "Home",
      component: () => import("@/Component/Containers/DefaultContainer"), // DefaultContainer,
      beforeEnter: ifNotAuthenticated,
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: () => import("@/Component/Dashboard"),
        },

        {
          path: "splashscreen",
          name: "Splash Screen",
          component: () => import("@/Component/SplashScreen/SplashScreen"),
        },

        {
          path: "compare",
          name: "Compare",
          component: () => import("@/Component/Compare"),
        },
        {
          path: "banners",
          name: "Banner Management V2",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "bannerlist-v2",
              name: "Banner List",
              component: () => import("@/Component/Banner/BannerListV2"),
            },
            {
              path: "addbanner-v2",
              name: "Add Movie Banner V2",
              component: () => import("@/Component/Banner/AddBannerV2"),
            },
            {
              path: "addstaticbanner-v2",
              name: "Add Static Banner V2",
              component: () => import("@/Component/Banner/AddStaticBannerV2"),
            },
          ],
        },
        {
          path: "now-showing",
          name: "Now Showing Movies",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "list",
              name: "List",
              component: () => import("@/Component/NowShowingMovies/List"),
            },
          ],
        },
        {
          path: "cinecoshow",
          name: "Cineco Shows",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "cinecoshowdetails",
              name: "Cineco Show Details",
              component: () =>
                import("@/Component/CinecoShow/CinecoShowDetails"),
            },
          ],
        },
        {
          path: "eventinfo",
          name: "Event Info",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "addeventinfo",
              name: "Event",
              component: () => import("@/Component/EventInfo/AddEventInfo"),
            },
            {
              path: "vieweventinfo",
              name: "View Events",
              component: () => import("@/Component/EventInfo/ViewEventInfo"),
            },
            {
              path: "blockseat",
              name: "Block Seats",
              component: () => import("@/Component/EventInfo/BlockSeats"),
            },
            {
              path: "seat-layout",
              name: "Seat Layout",
              component: () => import("@/Component/EventInfo/SeatLayout"),
            },
          ],
        },
        {
          path: "events",
          name: "Event Category",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "eventlist",
              name: "Event List",
              component: () => import("@/Component/EventCategory/EventList"),
            },
            {
              path: "addevent",
              name: "Add Event",
              component: () =>
                import("@/Component/EventCategory/AddEventCategory"),
            },
          ],
        },
        {
          path: "events",
          name: "Event Manager",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "eventmanagerlist",
              name: "Event Manager List",
              component: () =>
                import("@/Component/EventManager/ListEventManager"),
            },
            {
              path: "addeventmanager",
              name: "Add Event Manager",
              component: () =>
                import("@/Component/EventManager/AddEventManager"),
            },
          ],
        },
        {
          path: "events",
          name: "Event Banner",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "eventbannerlist",
              name: "Event Banner List",
              component: () =>
                import("@/Component/EventBanner/ListEventBanner"),
            },
            {
              path: "addeventbanner",
              name: "Add Event Banner",
              component: () => import("@/Component/EventBanner/AddEventBanner"),
            },
          ],
        },
        {
          path: "reports-data",
          name: "Reports",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "view-reports",
              name: "View Reports",
              component: () =>
                import("@/Component/ReportsData/ReportsDataView"),
            },
          ],
        },
        {
          path: "banners",
          name: "Banner Management",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "bannerlist",
              name: "Banner List",
              component: () => import("@/Component/Banner/BannerList"),
            },
            {
              path: "addbanner",
              name: "Add Movie Banner",
              component: () => import("@/Component/Banner/AddBanner"),
            },
            {
              path: "addstaticbanner",
              name: "Add Static Banner",
              component: () => import("@/Component/Banner/AddStaticBanner"),
            },
          ],
        },
        {
          path: "cron-management",
          redirect: "/cron-management/cron-job",
          name: "Cron Management",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "cron-job",
              name: "Manage Crons",
              component: () => import("@/Component/CronManagement/CronJobs"),
            },
          ],
        },

        {
          path: "user-management",
          redirect: "/user-management/add-user",
          name: "User Management",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "add-user",
              name: "Add User",
              component: () => import("@/Component/UserManagement/AddUser"), // AddUser
            },
            {
              path: "dash-users",
              name: "Dashboard Users",
              component: () => import("@/Component/UserManagement/DashUsers"), // DashUsers
            },
            {
              path: "app-users",
              name: "Application Users",
              component: () => import("@/Component/UserManagement/AppUsers"), // AppUsers
            },
            {
              path: "user-offer",
              name: "User Offer",
              component: () => import("@/Component/UserManagement/UserOffer"), // User Offer
            },
          ],
        },
        {
          path: "/org-management",
          redirect: "/org-management/organization/add-org",
          name: "Organization Management",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "organization",
              name: "Organization",
              redirect: "/org-management/organization/add-org",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "add-org",
                  name: "Add Organization",
                  component: () =>
                    import(
                      "@/Component/OrganizationManagement/organization/AddOrganization"
                    ), // AddOrganization
                },
                {
                  path: "all-orgs",
                  name: "List of Organizations",
                  component: () =>
                    import(
                      "@/Component/OrganizationManagement/organization/AllOrganizations"
                    ), // AllOrganizations
                },
              ],
            },
            // {
            //   path: "schedule",
            //   redirect: "/org-management/schedule/schedule-details",
            //   name: "Show Schedule Details",
            //   component: {
            //     render(c) {
            //       return c("router-view");
            //     },
            //   },
            //   children: [
            //     {
            //       path: "schedule-details",
            //       name: "Schedule Details",
            //       component: () =>
            //         import(
            //           "@/Component/OrganizationManagement/schedule/ScheduleDetails"
            //         ), // AllCinemas
            //     },
            //   ],
            // },
            {
              path: "cinemas",
              redirect: "/org-management/cinemas/all-cinemas-v2",
              name: "Cinemas",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "all-cinemas-v2",
                  name: "List of Cinemas V2",
                  component: () =>
                    import(
                      "@/Component/OrganizationManagement/cinemas/AllCinemasV2"
                    ), // AllCinemas
                },
                {
                  path: "add-cinema-v2",
                  name: "Add Cinemas V2",
                  component: () =>
                    import(
                      "@/Component/OrganizationManagement/cinemas/AddCinemaV2"
                    ), // AllCinemas
                },
              ],
            },
            {
              path: "cinemas",
              redirect: "/org-management/cinemas/all-cinemas",
              name: "Cinemas",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "add-cinema",
                  name: "Add Cinema",
                  component: () =>
                    import(
                      "@/Component/OrganizationManagement/cinemas/AddCinema"
                    ), // AddCinema
                },
                {
                  path: "add-cinema-charges",
                  name: "Add Cinema Charges",
                  component: () =>
                    import(
                      "@/Component/OrganizationManagement/cinemas/AddCinemaCharges"
                    ), // AddCinemaCharges
                },
                {
                  path: "all-cinemas",
                  name: "List of Cinemas",
                  component: () =>
                    import(
                      "@/Component/OrganizationManagement/cinemas/AllCinemas"
                    ), // AllCinemas
                },
              ],
            },
            {
              path: "screens",
              redirect: "/org-management/screens/add-screen",
              name: "Screens",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "compare-screen",
                  name: "Compare Screen",
                  component: () =>
                    import(
                      "@/Component/OrganizationManagement/screens/CompareScreen"
                    ), // AddScreen
                },
                {
                  path: "add-screen",
                  name: "Add Screen",
                  component: () =>
                    import(
                      "@/Component/OrganizationManagement/screens/AddScreen"
                    ), // AddScreen
                },
                {
                  path: "add-screen-seatlayout",
                  name: "Screen Seat Layout",
                  component: () =>
                    import(
                      "@/Component/OrganizationManagement/screens/ScreenSeatLayout"
                    ), // ScreenSeatLayout
                },
                {
                  path: "all-screens",
                  name: "All Screens",
                  component: () =>
                    import(
                      "@/Component/OrganizationManagement/screens/AllScreens"
                    ), // AllScreens
                },
                {
                  path: "add-master-seatlayout",
                  name: "Master Seat Layout",
                  component: () =>
                    import(
                      "@/Component/OrganizationManagement/screens/MasterSeatLayout"
                    ), // ScreenSeatLayout
                },
              ],
            },
          ],
        },
        {
          path: "gogo-settings",
          name: "GogoSettings",
          component: () => import("@/Component/Gogo-Settings"), // GogoSettings
        },
        {
          path: "manual-bookings",
          name: "Manual Bookings",
          component: () => import("@/Component/ManualBookings"),
        },
        {
          path: "manual-bookings/seat-bookings",
          name: "Manual Seat Bookings",
          component: () => import("@/Component/ManualSeatBookings"),
        },
        //enevt
        {
          path: "event-master",
          redirect: "/event-master/listofevents",
          name: "Event Master",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "listofevents",
              name: "ListOfEvent",
              component: () => import("@/Component/EventMaster/ListOfEvents"),
            },
          ],
        },
        {
          path: "view-print-ticket",
          name: "View/Print Ticket",
          component: () => import("@/Component/Ticket/ViewPrintTicket"),
        },
        // {
        //   path: "CinemaMovieCountsaspx",
        //   name: "Schedules Check",
        //   component: () => import("@/Component/SchedulesDetails/SchedulesCheck"),
        // },
        {
          path: "schedules",
          redirect: "schedules/CinemaMovieCountsaspx",
          name: "Schedules Check",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "CinemaMovieCountsaspx",
              name: "Schedules Check",
              component: () =>
                import("@/Component/SchedulesDetails/SchedulesCheck"), // ShowDateReportV2
            },
            {
              path: "scheduleForm",
              name: "Manual Schedule Sync",
              component: () =>
                import("@/Component/SchedulesDetails/ScheduleForm"),
            },
          ],
        },

        {
          path: "transaction-log",
          name: "Transaction Log",
          component: () => import("@/Component/Logs/TransactionLog"),
        },
        {
          path: "ratings",
          redirect: "ratings/censor-ratings",
          name: "Censor Ratings",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "censor-ratings",
              name: "Censor Ratings List",
              component: () => import("@/Component/Ratings/CensorRatings"), // ShowDateReportV2
            },
          ],
        },
        {
          path: "ratings/censor-ratings-form/:ID",
          name: "Add Censor Ratings",
          component: () => import("@/Component/Ratings/CensorRatingsForm"), // GogoSettings
        },
        {
          path: "reports",
          redirect: "reports/accounts/details-report",
          name: "ReportsV2",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/reports/accounts/details-report",
              name: "AccountsReport",
              component: () =>
                import("@/Component/Reports/Accounts/DetailsReport"),
            },
            {
              path: "/reports/accounts/accounts-report",
              name: "AccountsReport",
              component: () =>
                import("@/Component/Reports/Accounts/AccountsReport"),
            },
            {
              path: "/reports/accounts/detail-accounts-report",
              name: "Detail Accounts Report",
              component: () =>
                import("@/Component/Reports/Accounts/DetailAccountsReport"),
            },
            {
              path: "/reports/accounts/booking-details-report",
              name: "Booking Details Report",
              component: () =>
                import("@/Component/Reports/Accounts/BookingDetailsReport"),
            },
            {
              path: "/reports/accounts/internal-accounts-report",
              name: "Internal Accounts Report",
              component: () =>
                import("@/Component/Reports/Accounts/InternalAccountsReport"),
            },
            {
              path: "/reports/accounts/novo-accounts-report",
              name: "Novo Accounting Report",
              component: () =>
                import("@/Component/Reports/Accounts/NovoAccountsReport"),
            },
            {
              path: "/reports/accounts/mukta-accounts-report",
              name: "Mukta Accounting Report",
              component: () =>
                import("@/Component/Reports/Accounts/MuktaAccountsReport"),
            },
          ],
        },
        // {
        //   path: "reports",
        //   redirect: "reports/show-date-v2",
        //   name: "ReportsV2",
        //   component: {
        //     render(c) {
        //       return c("router-view");
        //     },
        //   },
        //   children: [
        //     // {
        //     //   path: "show-date-v2",
        //     //   name: "ShowDateReport",
        //     //   component: () => import("@/Component/Reports/ShowDateNewV2"), // ShowDateReportV2
        //     // },
        //     // {
        //     //   path: "transaction-date-v2",
        //     //   name: "TransactionDateReport",
        //     //   component: () => import("@/Component/Reports/TransactionDateV2"), // TransactionDateReportV2
        //     // },
        //     {
        //       path: "accounts",
        //       redirect: "/reports/accounts/accounts-report",
        //       name: "Accounts",
        //       component: {
        //         render(c) {
        //           return c("router-view");
        //         },
        //       },
        //       children: [
        //         {
        //           path: "/reports/accounts/details-report",
        //           name: "AccountsReport",
        //           component: () =>
        //             import("@/Component/Reports/Accounts/DetailsReport"),
        //         },
        //         {
        //           path: "/reports/accounts/accounts-report",
        //           name: "AccountsReport",
        //           component: () =>
        //             import("@/Component/Reports/Accounts/AccountsReport"),
        //         },
        //         {
        //           path: "/reports/accounts/detail-accounts-report",
        //           name: "Detail Accounts Report",
        //           component: () =>
        //             import("@/Component/Reports/Accounts/DetailAccountsReport"),
        //         },
        //         {
        //           path: "/reports/accounts/booking-details-report",
        //           name: "Booking Details Report",
        //           component: () =>
        //             import("@/Component/Reports/Accounts/BookingDetailsReport"),
        //         },
        //         {
        //           path: "/reports/accounts/internal-accounts-report",
        //           name: "Internal Accounts Report",
        //           component: () =>
        //             import(
        //               "@/Component/Reports/Accounts/InternalAccountsReport"
        //             ),
        //         },
        //       ],
        //     },
        //   ],
        // },
        {
          path: "reports",
          redirect: "/reports/show-date",
          name: "Reports",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "show-date",
              name: "ShowDateReport",
              component: () => import("@/Component/Reports/ShowDateNew"), // ShowDateReport
            },
            // {
            //   path: 'show-date-v2',
            //   name: 'ShowDateReport',
            //   component: () => import('@/Component/Reports/ShowDateNewV2') // ShowDateReportV2
            // },
            {
              path: "transaction-date",
              name: "TransactionDateReport",
              component: () => import("@/Component/Reports/TransactionDate"), // TransactionDateReport
            },
            // {
            //   path: 'transaction-date-v2',
            //   name: 'TransactionDateReport',
            //   component: () => import('@/Component/Reports/TransactionDateV2') // TransactionDateReportV2
            // },
            {
              path: "purchase-voucher",
              name: "PurchaseVoucher",
              component: () => import("@/Component/Reports/PurchaseVoucher"), // PurchaseVoucher
            },
            {
              path: "reconciliation",
              name: "ReconciliationReport",
              component: () => import("@/Component/Reports/Reconciliation"), // TransactionDateReport
            },
            {
              path: "comparetransactions",
              name: "CompareTransactions",
              component: () =>
                import("@/Component/Reports/CompareTransactions"), // CompareTransaction
            },
            {
              path: "comparesettlementtransaction",
              name: "CompareSettlementTransaction",
              component: () =>
                import("@/Component/Reports/CompareSettlementTransaction"), // CompareSettlementTransaction
            },
          ],
        },
        {
          path: "/movies",
          redirect: "/movies/all-movies",
          name: "Movies",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "add-movie",
              name: "Add Movie",
              component: () => import("@/Component/Movies/AddMoviesNew"), // AddMovies //AddMoviesNew
            },
            {
              path: "all-movies",
              name: "All Movies",
              component: () => import("@/Component/Movies/AllMovies"), // AllMovies
            },
            {
              path: "link-movies",
              name: "Link Movies",
              component: () => import("@/Component/Movies/LinkMovies"), // MapMovies
            },
            {
              path: "map-movies",
              name: "Map Movies",
              component: () => import("@/Component/Movies/MapMovies"), // MapMovies
            },
            {
              path: "add-critic-rating",
              name: "Add Movie Critics",
              component: () => import("@/Component/Movies/AddMovieCritics"), // Add Critics
            },
            {
              path: "add-coming-soon",
              name: "Add Coming Soon",
              component: () => import("@/Component/Movies/AddComingSoonMovie"), // Add Coming Soon
            },
            {
              path: "list-critic-rating",
              name: "List Movie Critics",
              component: () => import("@/Component/Movies/ListMovieCritics"), // Add Critics
            },
            {
              path: "addvoucher",
              name: "Add Voucher",
              component: () => import("@/Component/Movies/AddVoucher"),
            },
            {
              path: "voucherlist",
              name: "Voucher List",
              component: () => import("@/Component/Movies/VoucherList"),
            },
          ],
        },
        {
          path: "/masters",
          redirect: "/masters/countries",
          name: "Masters",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "countries",
              name: "Countries",
              component: () => import("@/Component/Masters/Countries"), // Countries
            },
            {
              path: "media",
              name: "media",
              component: () => import("@/Component/Masters/Media"), // Media
            },
            {
              path: "states",
              name: "States",
              component: () => import("@/Component/Masters/States"), // States
            },
            {
              path: "Cities",
              name: "Cities",
              component: () => import("@/Component/Masters/Cities"), // Cities
            },
            {
              path: "areas",
              name: "Areas",
              component: () => import("@/Component/Masters/Areas"), // Areas
            },
            {
              path: "language",
              name: "Language",
              component: () => import("@/Component/Masters/Language"), // Language
            },
            {
              path: "genre",
              name: "Genre",
              component: () => import("@/Component/Masters/Genre"), // Genre
            },
            {
              path: "info-type",
              name: "InfoType",
              component: () => import("@/Component/Masters/InfoType"), // InfoType
            },
            {
              path: "show-category",
              name: "Show Category",
              component: () => import("@/Component/Masters/ShowCategory"), // ShowCategory
            },
            // {
            //   path: 'add-template-type',
            //   name: 'Template Type',
            //   component: () => import('@/Component/Masters/TemplateType') // TemplateType
            // },
            {
              path: "template",
              name: "Template",
              component: () => import("@/Component/Masters/Template"), // Template
            },
          ],
        },
        {
          path: "notifications",
          redirect: "/notifications/send-notification",
          name: "Notifications",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "send-notification",
              name: "Send Notification",
              component: () =>
                import("@/Component/Notifications/SendNotification"),
            },
          ],
        },
        {
          path: "programming",
          redirect: "/programming/add-programming",
          name: "Programming",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "add-programming",
              name: "Add Programming",
              component: () =>
                import("@/Component/Programming/CreateProgramming"), // CreateProgramming
            },
            {
              path: "add-programming-category",
              name: "Add Programming Category",
              component: () =>
                import("@/Component/Programming/CreateProgrammingCategory"), // CreateProgrammingCategory
            },
            {
              path: "all-programming",
              name: "List Of Programming",
              component: () =>
                import("@/Component/Programming/ListOfProgramming"), // ListOfProgramming
            },
            {
              path: "send-confirmation",
              name: "Send Confirmation",
              component: () =>
                import("@/Component/Programming/SendConfirmation"), // SendConfirmation
            },
            // {
            //   path: 'screenings',
            //   redirect: '/screenings/all-screenings',
            //   name: 'Screenings',
            //   component: {
            //     render(c) { return c('router-view') }
            //   },
            //   children: [
            //     {
            //       path: 'all-screenings',
            //       name: 'AllScreenings',
            //       component: () => import('@/Component/programming/CustomScreenings/AllScreenings') // AllScreenings
            //     },
            //     {
            //       path: 'add-screening',
            //       name: 'Add Screening',
            //       component: () => import('@/Component/programming/CustomScreenings/CreateScreening') // CreateScreening
            //     },
            //   ]
            // },
          ],
        },
        {
          path: "third-party",
          redirect: "/third-party/showbizz",
          name: "Third-Party",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "showbizz",
              name: "Showbizz",
              component: () => import("@/Component/ThirdParty/Showbizz"), // Showbizz
            },
            {
              path: "vista",
              name: "Vista",
              component: () => import("@/Component/ThirdParty/Vista"), // Vista
            },
            {
              path: "manual-sync",
              name: "Manual Sync",
              component: () => import("@/Component/ThirdParty/ManualSync"), // ManualSync
            },
          ],
        },
        {
          path: "content",
          redirect: "/content/terms-and-conditions1",
          name: "Content",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "add-content",
              name: "Add Content",
              component: () => import("@/Component/Content/AddContent"), // AddContent
            },
            // {
            //   path: 'terms-and-conditions1',
            //   name: 'Terms And Conditions 1',
            //   component: () => import('@/Component/content/TermsAndConditions1') // TermsAndConditions1
            // },
            // {
            //   path: 'terms-and-conditions2',
            //   name: 'Terms And Conditions 2',
            //   component: () => import('@/Component/content/TermsAndConditions2') // TermsAndConditions2
            // }
          ],
        },
        {
          path: "cineasia-distributors",
          name: "CineAsiaDistributors",
          component: () => import("@/Component/CineAsiaDistributors"), // CineAsiaDistributors
        },
        {
          path: "cineasia-cinemas",
          name: "CineAsiaCinemas",
          component: () => import("@/Component/CineAsiaCinemas"), // CineAsiaCinemas
        },
        {
          path: "vendor-master",
          name: "VendorMaster",
          component: () => import("@/Component/VendorMaster"), // VendorMaster
        },
        {
          path: "coupon-code",
          name: "CouponCode",
          component: () => import("@/Component/CouponCode"), // CouponCode
        },
        {
          path: "admin-finance",
          redirect: "/admin-finance/report",
          name: "Finance",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "report",
              name: "Finance Report",
              component: () =>
                import("@/Component/Finance/Admin-finance-report"), // AdminReport
            },
            {
              path: "add-transfer",
              name: "Add Transfer",
              component: () => import("@/Component/Finance/AddTransfer"), // AddTransfer
            },
          ],
        },
        {
          path: "cin-finance-report",
          name: "Cinema Finance Report",
          component: () => import("@/Component/Cin-finance-report"), // CinemaReport
        },
        {
          path: "organization-finance-report",
          name: "Organization Finance Report",
          component: () => import("@/Component/Cin-finance-report"), // CinemaReport
        },
        {
          path: "users-report",
          name: "Users-Report",
          component: () => import("@/Component/Users-Report"), // UsersReport
        },
      ],
    },
    {
      path: "pages",
      redirect: "/pages/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "500",
          name: "Page500",
          component: () => import("@/Component/pages/Page500"), // Page500
        },
      ],
    },
    {
      path: "/go-to/login",
      name: "Login",
      component: () => import("@/Component/Login"), // Login
    },
    // and finally the default route, when none of the above matches:
    {
      path: "*",
      name: "Page404",
      component: () => import("@/Component/pages/Page404"), // Page404
    },

    // Cinema And Distributor
    {
      path: "/cinemamasters/",
      name: "Cinema Masters",
      component: () => import("@/Component/CinemaAdminDashboardHome"),
      children: [
        {
          path: "",
          redirect: "/cinemamasters/adminreports",
        },
        {
          path: "adminreports",
          name: "Master Admin",
          component: () => import("@/Component/CinemaDashboard/pages/Home"),
          // component: () => import('@/Component/CinemaAdminDashboardHome')
        },
        {
          path: "adminticketreportslist",
          name: "Admin Reports List",
          component: () =>
            import("@/Component/CinemaDashboard/pages/CinemaTicket"),
        },
        {
          path: "show-reports",
          name: "Show Report List",
          component: () =>
            import("@/Component/CinemaDashboard/pages/ShowReportList"),
        },
        {
          path: "transaction-reports",
          name: "Transaction Report List",
          component: () =>
            import("@/Component/CinemaDashboard/pages/TransactionReportList"),
        },
        {
          path: "seatlayout",
          name: "Admin Seat Layput",
          component: () =>
            import("@/Component/CinemaDashboard/pages/seatLayout"),
        },
        {
          path: "support",
          name: "Support",
          component: () => import("@/Component/CinemaDashboard/pages/Support"),
        },
      ],
    },
  ],
});
