import axios from 'axios';
import Vue from 'vue';
import router from '../router/index';
import { BASEURL } from "../helper/constant.js";


// http://api.ticketak.com
export default () => {
    const instance = axios.create({
        // baseURL: ' http://172.15.1.202'
        // baseURL: 'http://windows.binarynumbers.io'
        // baseURL: 'http://ticketak.binarynumbers.io'
        // baseURL: 'http://gogocinema.binarynumbers.io'
        // baseURL: BaseURL
        // Saif
        // baseURL: 'http://api.ticketak.com'
        baseURL: BASEURL
    })
    instance.defaults.headers.common['Authorization'] = Vue.cookie.get('TICHTID')
    // instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    instance.interceptors.response.use(function (response) {
        // Do something with response data
        return response;
    }, function (error) {
        // Do something with response error
        if (error.response.status == 401) {
            localStorage.clear()
            Vue.cookie.delete('TICHTID');
            // Vue.cookie.delete('token_type');
            router.push({ path: '/go-to/login' })
        }
        return Promise.reject(error);
    });
    return instance;
}