import Api from './Api'

export default {

    // New Saif
    getUserByID(payload) {
        return Api().post('/api/dashuser/GetUserByID', payload)
    },
    getUsers() {
        return Api().post('/api/dashuser/userlist')
    },
    searchSingleUser(payload) {
        return Api().post('api/Account/SearchUser', payload)
    },

    /**
     * Back
     */
    // getUserRoles() {
    //     return Api().post('/api/dashuser/GetUserRole')
    // },
    // getDistributorList() {
    //     return Api().post('api/dashuser/GetddlDistributorList/')
    // },
    // addUser(payload) {
    //     return Api().post('/api/dashuser/addUser', payload)
    // },

    /**
     * NEW SAIF
     */

    // NOTE: Moved into Cinemas Service
    // getDistributorList() {
    //     return Api().post('admin/getactivedistributors/')
    // },

    getUserRoles() {
        return Api().post('admin/getuserrole')
    },
    addUser(payload) {
        return Api().post('admin/addUser', payload)
    },

    addOffer(payload) {
        return Api().post('admin/addOfferSettings', payload)
    },
    listOffer() {
        return Api().post('admin/viewOfferSettings')
    }
}