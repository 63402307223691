// export const BASEURL = 'http://api.ticketak.com';
// export const BASEURL = 'http://api2.ticketak.com';

//export const BASEURL = 'https://dashboard-api.ticketak.com';

//With dev server
// export const BASEURL = window.location.hostname.includes("localhost") || window.location.hostname.includes("wanasadevdashboard.enpointe.io")
//   ? "https://wanasaadminapidev.enpointe.io"
//   : "https://adminapi-wanastime.wanasatime.com";

// export const BASEURLEVENTS = window.location.hostname.includes("localhost") || window.location.hostname.includes("wanasadevdashboard.enpointe.io")
//   ? "https://wanasaeventdev.enpointe.io"
//   : "https://wanasaevent.enpointe.io";

// export const BASEURLUPLOAD = window.location.hostname.includes("localhost") || window.location.hostname.includes("wanasadevdashboard.enpointe.io")
//   ? "https://wanasaadminapidev.enpointe.io"
//   : "https://adminapi-wanastime.wanasatime.com";

//With live server
export const BASEURL = window.location.hostname.includes("localhost")
  ? "https://adminapi-wanastime.wanasatime.com"
  : "https://adminapi-wanastime.wanasatime.com";

export const BASEURLEVENTS = window.location.hostname.includes("localhost")
  ? "https://wanasaevent.enpointe.io"
  : "https://wanasaevent.enpointe.io";

export const BASEURLUPLOAD = window.location.hostname.includes("localhost")
  ? "https://adminapi-wanastime.wanasatime.com"
  : "https://adminapi-wanastime.wanasatime.com";

// My Tiketi BASEURL
//export const BASEURL = 'https://devapi.mytiketi.com';
//export const BASEURL = 'http://dev-dashboard.mytiketi.com';

//export const BASEURL = 'http://devadminapi.mytiketi.com';

// Your Google Cloud Platform project ID
export const googleProjectId = "fitness-kart";

// Project Name
export const dashboardName = "Ticketak";
export const WANASHAdashboardName = "WANASA_TIME";
//export const dashboardName = "MyTiketi";
export const pdfFillColor = [47, 23, 73];
