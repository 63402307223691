import UserService from "@/services/UserService";

export default {
    namespaced: true,
    state: {
        roles: [],
    },
    getters: {
        getRoles: state => state.roles,
    },
    mutations: {
        SET_ROLE_DETAILS(state, val) {
            state.roles = val
        },
    },
    actions: {
        setRole: async ({ dispatch, commit, state }, payload) => {
            await commit('SET_ROLE_DETAILS', []);
            UserService.getUserRoles()
                .then(async (response) => {
                    if (response.data.Status) {
                        await commit('SET_ROLE_DETAILS', response.data.Userrole)
                    } else {
                        console.log(response.data);
                    }
                })
                .catch(err => {
                    console.log('Error in getting roles ', err);
                })
        },
    },
}