import Api from "./Api";
import EventApi from "./EventApi";
import EventsApi from "./EventsApi";

export default {
  addTemplateType(body) {
    return Api().post("api/dashuser/AddTemplateType", body);
  },

  addGogoSettings(body) {
    if (body.GogoSettingID != null) {
      body.Flag = 2;
    } else {
      body.GogoSettingID = undefined;
      body.Flag = 1;
    }
    return Api().post("api/dashuser/AddGoGoSetting", body);
  },

  getTemplateTypes() {
    return Api().get("api/dashuser/GetTemplateTypeList");
  },

  closeSeatLayoutEventList() {
    return EventsApi().get("/v1/event/new/closeSeatLayoutEventList");
  },

  GetSeatLayout(schedule_id) {
    return EventsApi().post(`/v1/event/getseatlayout/${schedule_id}`);
  },

  getAudSchedule(aud_id) {
    return EventsApi().get(`/v1/event/new/getAudSchedule/${aud_id}`);
  },

  blockScheduleSeats(schedule_id, status_id, payload) {
    return EventsApi().post(
      `/v1/event/blockSeats/${schedule_id}/${status_id}`,
      payload
    );
  },

  getGogoSettings(ID) {
    return Api().get("api/dashuser/GetGoGoSetting");
  },

  getAllAuditorium() {
    return EventApi().get("/v1/auditorium?page=all");
  },

  getAllPageEvent() {
    return EventApi().get("/v1/event?page=all");
  },

  getAllEvent() {
    return EventApi().get("/v1/event");
  },

  getTemplateTypeByID(TemplateTypeID) {
    return Api().get(
      "api/dashuser/GetTemplateTypeByID?TemplateTypeID=" + TemplateTypeID
    );
  },

  getTemplateTypesDDL() {
    return Api().get("api/dashuser/GetTemplateddlTypeList");
  },

  downloadCsv(LanguageFile) {
    return Api().get("api/dashuser/DownloadCSV?LanguageFile=" + LanguageFile);
  },

  // New Saif

  getCountriesDDL() {
    return Api().post("api/dashuser/GetddlCountryList");
  },
  getStatesDDL(payload) {
    return Api().post("api/dashuser/GetddlStateList", payload);
  },
  getCitiesDDL(payload) {
    return Api().post("api/dashuser/GetddlCityList", payload);
  },
  getCountryByID(payload) {
    return Api().post("api/dashuser/GetCountryByID", payload);
  },
  addCountry(payload) {
    return Api().post("api/dashuser/AddCountry", payload);
  },
  getStateByID(payload) {
    return Api().post("api/dashuser/GetStateByID", payload);
  },
  getStates(payload) {
    return Api().post("api/dashuser/GetStateList", payload);
  },
  addState(body) {
    return Api().post("api/dashuser/AddState", body);
  },
  getCityByID(payload) {
    return Api().post("api/dashuser/GetCityByID", payload);
  },
  getCities(payload) {
    return Api().post("api/dashuser/GetCityList", payload);
  },
  getAreaByID(payload) {
    return Api().post("api/dashuser/GetAreaByID", payload);
  },
  addArea(payload) {
    return Api().post("api/dashuser/AddArea", payload);
  },
  addCity(payload) {
    return Api().post("api/dashuser/AddCity", payload);
  },
  getAreas(payload) {
    return Api().post("api/dashuser/GetAreaList", payload);
  },
  getLanguageByID(payload) {
    return Api().post("api/dashuser/GetLanguageByID", payload);
  },
  addLanguage(payload) {
    return Api().post("api/dashuser/AddLanguage", payload);
  },
  getLanguages() {
    return Api().post("api/dashuser/GetLanguageList");
  },
  getGenreByID(payload) {
    return Api().post("api/dashuser/GetGenreByID", payload);
  },
  addGenre(payload) {
    return Api().post("api/dashuser/AddGenre", payload);
  },
  getGenres() {
    return Api().post("api/dashuser/GetGenreList");
  },
  getInfoTypeByID(payload) {
    return Api().post("api/dashuser/GetInfoTypeByID", payload);
  },
  addInfoType(payload) {
    return Api().post("api/dashuser/AddInfoType", payload);
  },
  getInfoType() {
    return Api().post("api/dashuser/GetInfoTypeList");
  },
  getCategoryByID(payload) {
    return Api().post("api/dashuser/GetCategoryByID", payload);
  },
  addCategory(payload) {
    return Api().post("api/dashuser/AddCategory", payload);
  },
  getCategories() {
    return Api().post("api/dashuser/GetCategoryList");
  },
  getTemplateByID(payload) {
    return Api().post("api/dashuser/GetTemplateByID", payload);
  },
  addTemplate(payload) {
    return Api().post("api/dashuser/AddTemplate", payload);
  },
  getTemplates() {
    return Api().post("api/dashuser/GetTemplateList");
  },
  sendNotification(payload) {
    return Api().post("api/notifications/Send/", payload);
  },
  getShowbizzByID(payload) {
    return Api().post("api/showbizz/GetListShowbizzID", payload);
  },
  addShowbizz(payload) {
    return Api().post("api/showbizz/AddShowbizz", payload);
  },
  getShowbizzList() {
    return Api().post("api/showbizz/GetListShowbizz");
  },
  getVistaByID(payload) {
    return Api().post("api/vista/GetListVistaID", payload);
  },
  addVista(payload) {
    return Api().post("api/vista/AddVista", payload);
  },
  getVistaList() {
    return Api().post("api/vista/GetListVista");
  },
  syncData(payload) {
    return Api().post("api/thirdparty/SyncData/", payload);
  },
  getSyncList() {
    return Api().post("api/thirdparty/GetSyncList/");
  },
  getCategoriesDDL() {
    return Api().post("api/dashuser/GetddlCategoryList");
  },
  addContent(payload) {
    return Api().post("api/dashuser/AddContent/", payload);
  },
  getContentByID(payload) {
    return Api().post("api/dashuser/GetContentByID", payload);
  },
  getContentList() {
    return Api().post("api/dashuser/GetContentList/");
  },
  getCountries() {
    return Api().post("api/dashuser/GetCountryList");
  },

  // getDashboard() {
  //     return Api().post('api/dashuser/GetDashboard')
  // },
  // getBannerList() {
  //     return Api().post('/api/Banner/GetBannerList')
  // },
  // sortBanner(body) {
  //     return Api().post('api/Banner/UpdatePosition', body)
  // },
  // getCountriesDDL() {
  //     return Api().post('api/dashuser/GetddlCountryList')
  // },
  // activateOrDeactivate(payload) {
  //     return Api().post('api/dashuser/ChangeStatus', payload)
  // },

  // Started New API
  getDashboard() {
    return Api().post("admin/dashboard");
  },
  getDashboardData(payload) {
    return Api().post("admin/GraphData", payload);
  },
  getBannerList() {
    return Api().post("admin/getbannerlist");
  },
  sortBanner(body) {
    return Api().post("admin/sortbanners", body);
  },
  getActiveCountries() {
    return Api().post("admin/getactivecountries");
  },
  addBanner(body) {
    return Api().post("admin/addbanner", body);
  },
  getNowShowingMovies() {
    return Api().post("admin/NowShowingMovies");
  },
  sortNowShowingMovies(body) {
    return Api().post("admin/sortnowshowingmovies", body);
  },
  activateOrDeactivate(payload) {
    return Api().post("admin/changestatus", payload);
  },
  getBannerByID(payload) {
    return Api().post("admin/getbannerbyid", payload);
  },

  getSheduleData(payload) {
    return Api().post("admin/getcinecoshows", payload);
  },

  //Event Category
  getEventList() {
    return EventsApi().get("/v1/event_category?page=all");
  },
  addEventList(payload) {
    return EventsApi().post("/v1/event_category", payload);
  },
  updateEventList(payload, id) {
    return EventsApi().put(`/v1/event_category/${id}`, payload);
  },

  //Event Manager
  getEventManagerList() {
    return EventsApi().get("/v1/event_manager?page=all");
  },
  addEventManager(payload) {
    return EventsApi().post("/v1/event_manager", payload);
  },
  getEventManager(payload) {
    return EventsApi().get(`/v1/event_manager/${payload.event_manager_id}`);
  },
  updateEventManager(payload, id) {
    return EventsApi().put(`/v1/event_manager/${id}`, payload);
  },

  //Event Banner
  getEventBannerList() {
    return EventsApi().get("/v1/event_hbanner?page=all");
  },
  addEventBanner(payload) {
    return EventsApi().post("/v1/event_hbanner", payload);
  },
  getEventBannerId(payload) {
    return EventsApi().get(`/v1/event_hbanner/${payload.id}`);
  },
  updateEventBanner(payload, id) {
    return EventsApi().put(`/v1/event_hbanner/${id}`, payload);
  },

  //Add Event
  addEvent(payload) {
    return EventsApi().post("/v1/event", payload);
  },

  getEvent() {
    return EventsApi().post("/v1/event/filters");
  },

  filterEvents(params, payload) {
    return EventsApi().post(`/v1/event/filters${params}`, payload);
  },

  updateEventAllData(payload) {
    return EventsApi().get(`/v1/event/${payload.id}`);
  },

  updateEvent(payload, id) {
    return EventsApi().put(`/v1/event/${id}`, payload);
  },

  //get Event Reports
  getAllEventsReport() {
    return EventsApi().get("/v1/reports");
  },
  getAllEventsReportList(params, payload) {
    return EventsApi().post(`/v1/reports${params}`, payload);
  },

  //Filter Reports Event
  filterReports(payload) {
    return EventsApi().post("/v1/reports", payload);
  },

  //Reports Excel
  getReportsExcel(payload) {
    return EventsApi().get("/v1/reports/excel", payload);
  },
  getReportsPdf(payload) {
    return EventsApi().get("/v1/reports/pdf", payload);
  },

  //Add Voucher

  addVoucher(body) {
    return Api().post("admin/addVoucher", body);
  },

  getAllVouchers(body) {
    return Api().post("admin/getVouchers", body);
  },

  sendVouchers(body) {
    return Api().post("admin/sendVouchers", body);
  },

  //Cineco Show Details

  getCinecoSession() {
    return Api().post("admin/cinecosessions");
  },

  //get Countries

  getCountryEvent() {
    return Api().post("/GetCountryList");
  },

  //send reports email
  resendReportsEmail(payload) {
    return EventsApi().post(`/v1/payment/resentemail/`, payload);
  },

  //Manual Schedule Sync Details

  getManualScheduleSync(payload) {
    return Api().post(`/api/cineco/manual-sync`, payload);
  },

  getSingleCinecoModalDetails(payload) {
    return Api().post(
      "api/cineco/get-single-movie-schedule/" +
        payload.cinema +
        "/" +
        payload.filmKey
    );
    // return Api().get('/api/getseatlayout.php?seatLayoutKey=' + seatLayoutKey)alhamra/11691=
  },
};
