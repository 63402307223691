const state = {
    authenticated: false
}

const mutations = {
    SET_AUTH : (state, status) => {
        state.authenticated = status
    }
}

const actions = {
    setAuth({commit}, status) {
        commit('SET_AUTH', status)
    }
}

const getters = {
    authenticated: state => state.authenticated
}

export default {
    state,
    mutations,
    actions,
    getters
}