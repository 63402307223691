const $ = require('jquery')
import axios from 'axios';
import Vue from 'vue'
import { BASEURL } from "../helper/constant.js";

export default {
    // login(body) {
    //     return $.ajax({
    //         // url: 'http://api.ticketak.com/api/account/login',
    //         url: `${BASEURL}/api/account/login`,
    //         method: 'POST',
    //         data: body
    //     })
    //         .done((res) => {
    //             return res;
    //         })
    //         .catch((jqXHR, textStatus, errorThrown) => {
    //             return { message: errorThrown }
    //         })
    // },

    getIP() {
        // return axios.get('https://api.ipify.org?format=json')
        return axios.get('https://ipapi.co/json/')
    },
    Login(body) {
        //console.log('body :', body);
        return $.ajax({
            url: `${BASEURL}/admin/login`,
            method: 'POST',
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify(body),
            dataType: 'json',
        })
            .done((res) => {
                return res;
            })
            .catch((jqXHR, textStatus, errorThrown) => {
                return { message: errorThrown }
            })
    },
    Logout(body) {
        return $.ajax({
            url: `${BASEURL}/admin/logout`,
            method: 'POST',
            headers: {
                "Authorization": Vue.cookie.get('TICHTID')
            },
        })
            .done((res) => {
                return res;
            })
            .catch((jqXHR, textStatus, errorThrown) => {
                return { message: errorThrown }
            })
    }
}