<template>
  <div>
    <div id="showSnackbar"></div>
    <router-view v-if="accept"></router-view>
  </div>
</template>

<script>
import LoginService from "@/services/LoginService";
export default {
  name: "app",
  data() {
    return {
      accept: true
    };
  },
  beforeMount() {},
  mounted() {
    this.getIP();
  },
  methods: {
    async getIP() {
      let res = await LoginService.getIP();
      // if(res.data.ip == '110.173.187.106') {
      this.accept = true;
      // }
    }
  }
};
</script>

<style lang="scss">
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.min.css";
/* Import Font Awesome Icons Set */
$fa-font-path: "~font-awesome/fonts/";
@import "~font-awesome/scss/font-awesome.scss";
/* Import Simple Line Icons Set */
$simple-line-font-path: "~simple-line-icons/fonts/";
@import "~simple-line-icons/scss/simple-line-icons.scss";
/* Import Flag Icons Set */
@import "~flag-icon-css/css/flag-icon.min.css";
/* Import Bootstrap Vue Styles */
@import "~bootstrap-vue/dist/bootstrap-vue.css";
// Import Main styles for this application
@import "assets/scss/style";
</style>
<style>
.btn-secondary {
  color: #ffffff;
}
/* .navbar {
  background: #cc202e;
}
.navbar-brand-full {
  transform: scale(0.7);
}
.btn {
  background: #cc202e;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #cc202e;
  border-color: #cc202e;
}
.btn-primary:hover {
  color: #fff;
  background-color: #cc202e;
  border-color: #cc202e;
} */

.CustomVSelect .dropdown-toggle input {
  background: #ffffff !important;
}
.CustomVSelect input[type="search"],
.v-select input[type="search"]:focus {
  background: transparent !important;
}
.CustomVSelect.searchable .dropdown-toggle {
  background: #fff;
  height: 40px;
}
.CustomVSelect .dropdown-toggle .clear {
  display: none;
}
</style>
