import Vue from 'vue';
import VueX from 'vuex'
import login from './login'
import user from './user/index'
import common from './common/index'

Vue.use(VueX);

export default new VueX.Store({
    modules: {
        login,
        user,
        common,
    }
})