import Api from './Api'

export default {
    getBookingDetailsReport(payload) {
        return Api().post('api/dashuser/FinanceTransDate', payload)
    },

    getNovoAccountsReport(payload) {
        return Api().post('api/dashuser/NovoAccountingReport', payload)
    },

    getMuktaAccountsReport(payload) {
        return Api().post('api/dashuser/MuktaAccountingReport', payload)
    },

    getTranscationLog(payload) {
        return Api().post('admin/transactionlog', payload)
    },

    getInternalAccountsReport(payload) {
        return Api().post('api/dashuser/InternalAccountsReport', payload)
    },

    getDetailAccountsReport(payload) {
        return Api().post('api/dashuser/DetailAccountsReport', payload);
    },

    getAllCinemas(payload) {
        // api/dashuser/GetCinemaList
        return Api().post('api/dashuser/GetCinemaList', payload);
        // return Api().post('GetAllShow', payload);
    },

    getScreensByCinemaID(payload) {
        return Api().post('api/dashuser/GetScreenddl/', payload);
    },

    getMoviesByScreenID(payload) {
        return Api().post('admin/getactivemovies/', payload);
    },

    getShowTimeByMovieID(payload) {
        return Api().post('admin/getactiveshows/', payload);
    },

    getMovieByCinemaID(payload){
        return Api().post('', payload);
    }


}